import {AccrualRange} from "@/apiProxies/dataContracts/personalAccount";

export const hasMultipleRanges = <T extends { range: AccrualRange | null }>(groups: T[]): boolean => {
    if (!groups || groups.length === 0) return false;

    const ranges = groups.map(group => group.range)
        .filter(range => range !== null);

    if (ranges.length === 0) return false;

    const uniqueRanges = new Set(ranges);

    return uniqueRanges.size > 1;
};