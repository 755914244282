import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import { store } from "../index"
import ProxyFactory from "@/apiProxies/proxyFactory"
import {
    AccrualGroup,
    AccrualTariffGroup, AccrualTariffZoneGroup,
    BillingData,
    MutualSettlementData,
    PaymentDetail,
    PaymentType
} from '@/apiProxies/dataContracts/personalAccount'
import { isEmpty } from "lodash"

@Module({ store, name: 'billingModule', namespaced: true, dynamic: true, stateFactory: true })
export class Billing extends VuexModule {

    private _billingData: BillingData | undefined
    private _mutualSettlement: MutualSettlementData | undefined

    private _payments: PaymentDetail[] = []
    private _paymentData: PaymentDetail[] = []
    private _mutualSettlements: MutualSettlementData[] = []

    private _lastAccruals: AccrualGroup | undefined
    private _lastMutualSettlement: MutualSettlementData | undefined

    public get payments(): PaymentDetail[] {
        return this._payments
    }

    public get billingData(): BillingData | undefined {
        return this._billingData
    }

    public get lastAccruals(): AccrualGroup | undefined {
        return this._lastAccruals
    }

    public get paymentData(): PaymentDetail[] | undefined {
        return this._paymentData
    }

    public get mutualSettlementData(): MutualSettlementData | undefined {
        return this._mutualSettlement
    }

    public get mutualSettlements(): MutualSettlementData[] {
        return this._mutualSettlements
    }

    public get lastMutualSettlement(): MutualSettlementData | undefined {
        return this._lastMutualSettlement
    }

    @Mutation
    setBillingData(data: BillingData | undefined): void {
        this._billingData = data
    }

    @Mutation
    setLastAccruals(data: AccrualGroup | undefined): void {
        this._lastAccruals = data
    }

    @Mutation
    setPaymentData(data: PaymentDetail[]): void {
        this._paymentData = data
    }

    @Mutation
    setMutualSettlementData(data: MutualSettlementData | undefined): void {
        this._mutualSettlement = data
    }

    @Mutation
    setMutualSettlements(data: MutualSettlementData[]): void {
        this._mutualSettlements = data
    }

    @Mutation
    setPayments(data: PaymentDetail[]): void {
        this._payments = data
    }

    @Mutation
    setLastMutualSettlement(data: MutualSettlementData | undefined): void {
        this._lastMutualSettlement = data
    }

    @Action
    public async loadMutualSettlement(body: { contractId: string, period: Date }): Promise<void> {
        this.setMutualSettlementData(undefined)

        const proxy = await ProxyFactory.createBillingPresenterProxy()

        const result = await proxy.getMutualSettlement(body.contractId, body.period)

        const mutualSettlementData = isEmpty(result) ? undefined : result

        this.setMutualSettlementData(mutualSettlementData)
    }

    @Action
    public async loadMutualSettlements(body: { contractId: string, beginDate: Date, endDate: Date }): Promise<void> {
        this.setMutualSettlements([])

        const proxy = await ProxyFactory.createBillingPresenterProxy()

        const { contractId, beginDate, endDate } = body

        const result = await proxy.getMutualSettlements(contractId, beginDate, endDate)

        this.setMutualSettlements(result)
    }

    @Action
    public async loadBillingData(body: { contractId: string, period: Date }): Promise<void> {
        this.setBillingData(undefined)

        const proxy = await ProxyFactory.createBillingPresenterProxy()

        const result = await proxy.getBillingData(body.contractId, body.period)

        const billingData = isEmpty(result) ? undefined : result

        this.setBillingData(billingData)
    }

    @Action
    public async loadLastAccruals(contractId: string): Promise<void> {
        this.setLastAccruals(undefined)

        const proxy = await ProxyFactory.createBillingPresenterProxy()

        const result = await proxy.getLastAccruals(contractId)

        const lastAccruals = isEmpty(result) ? undefined : result

        this.setLastAccruals(lastAccruals)
    }

    @Action
    public async loadLastMutualSettlement(contractId: string): Promise<void> {
        this.setLastMutualSettlement(undefined)

        const proxy = await ProxyFactory.createBillingPresenterProxy()

        const result = await proxy.getLastMutualSettlement(contractId)

        const settlement = isEmpty(result) ? undefined : result

        this.setLastMutualSettlement(settlement)
    }

    @Action
    public async loadPaymentData(body: { contractId: string, period: Date }): Promise<void> {
        this.setPaymentData([])

        const proxy = await ProxyFactory.createBillingPresenterProxy()

        const result = await proxy.getPaymentData(body.contractId, body.period)

        const paymentData = isEmpty(result) ? [] : result

        this.setPaymentData(paymentData)
    }

    @Action
    public async loadPayments(body: { contractId: string, type: PaymentType, range: [Date, Date] }): Promise<void> {
        this.setPayments([])

        const proxy = await ProxyFactory.createBillingPresenterProxy()

        const { contractId, type, range } = body

        const result = await proxy.getPayments(contractId, type, range[0], range[1])

        this.setPayments(result || [])
    }
}

export const billingModule = getModule(Billing)